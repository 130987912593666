<script setup lang="ts">
import { computed, onBeforeMount, ref, type PropType, watch } from "vue";
import { storeToRefs } from "pinia";
import { PageLayout } from "@/layouts";
import {
  IconLink,
  FieldInput,
  FieldSelect,
  PfrButton,
  PfrForm,
  TitleHeader,
} from "@/components";
import { useI18n } from "vue-i18n";
import { useForm } from "vee-validate";
import { ButtonElement, RouteName, Unit } from "@/types";
import { useBlanketOrderStore, useUserStore } from "@/stores";
import { useRouter } from "vue-router";
import { useCheckDriverAndVehicleId } from "@/composables";

const router = useRouter();

useCheckDriverAndVehicleId();

interface OrderDetailsFields {
  selectedMaterial: string;
  targetWeight: number;
}

const props = defineProps({
  blanketOrderId: {
    type: String as PropType<string>,
    required: true,
  },
});

const { t } = useI18n();

const blanketOrderStore = useBlanketOrderStore();
const userStore = useUserStore();

const { fetchBlanketOrder } = blanketOrderStore;
const { error, fetching } = storeToRefs(blanketOrderStore);
const { vehicleId, driverId } = storeToRefs(userStore);
const targetWeightUnit = ref("");

const formId = "select-order-details-form";
const validationSchema = {
  selectedMaterial: "required",
  targetWeight: "required",
};

const { handleSubmit, values, meta } = useForm<OrderDetailsFields>({
  validationSchema,
});

const getTargetWeightUnit = (selectedMaterial: string) => {
  const blanketOrderItem = blanketOrderStore.blanketOrderItems.find(
    (bOI) => bOI.material.id === selectedMaterial,
  );

  return blanketOrderItem?.unit ?? "";
};

const targetWeightLabel = computed(() =>
  t("views.selectOrderDetails.targetWeight", {
    unit: targetWeightUnit.value,
  }),
);

const displayError = computed(() => {
  if (!error.value) return false;
  if (!error.value.graphQLErrors) return t("forms.error");

  return error.value.graphQLErrors.map((err) => err.message)?.join(". ");
});

const onSubmit = handleSubmit(async ({ selectedMaterial, targetWeight }) => {
  blanketOrderStore.createOrderFromBlanketOrder(
    props.blanketOrderId,
    selectedMaterial,
    targetWeight,
    targetWeightUnit.value as Unit,
    vehicleId.value,
    driverId.value,
  );
});

watch(values, () => {
  targetWeightUnit.value = getTargetWeightUnit(values.selectedMaterial);
});

blanketOrderStore.$onAction(({ name, after }) => {
  after(() => {
    if (name !== "createOrderFromBlanketOrder" || error.value) return;
    if (
      !blanketOrderStore.currentOrderId ||
      !blanketOrderStore.currentOrderItemId
    )
      return;

    router.push(
      `/orders/${blanketOrderStore.currentOrderId}/items/${blanketOrderStore.currentOrderItemId}`,
    );
  });
});

onBeforeMount(() => {
  fetchBlanketOrder(props.blanketOrderId);
});
</script>

<template>
  <PageLayout>
    <template #header>
      <TitleHeader :heading="t('views.selectOrderDetails.heading')">
        <template #left>
          <IconLink
            :to="{
              name: RouteName.OpenOrders,
            }"
          />
        </template>
      </TitleHeader>
    </template>

    <template #main>
      <p class="mb-24">
        <strong>{{ t("views.selectOrderDetails.hint") }}</strong>
      </p>

      <PfrForm :id="formId" @submit-form="onSubmit">
        <FieldSelect
          field-name="selectedMaterial"
          :label="t('views.selectOrderDetails.material')"
          :is-required="true"
          :options="blanketOrderStore.materialOptions"
        />

        <FieldInput
          field-name="targetWeight"
          :label="targetWeightLabel"
          type="number"
          min="0"
          :is-required="true"
        />
      </PfrForm>

      <pfreundt-infobox
        v-if="displayError"
        :text="displayError"
        :type="'error'"
        :close-after="3000"
      ></pfreundt-infobox>
    </template>

    <template #mainFooter>
      <PfrButton
        :is="ButtonElement.Button"
        type="submit"
        :form="formId"
        :full-width="true"
        :disabled="fetching || !meta.valid"
      >
        {{ t("views.selectOrderDetails.startOrder") }}
      </PfrButton>
    </template>
  </PageLayout>
</template>
