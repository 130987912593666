<script setup lang="ts">
import {
  computed,
  watch,
  type PropType,
  onBeforeMount,
  ref,
  type Ref,
} from "vue";
import { useI18n } from "vue-i18n";
import { PageLayout } from "@/layouts";
import {
  ButtonElement,
  LoadingState,
  RouteName,
  ScaleStatus,
  Unit,
} from "@/types";
import {
  CancelOrderDialog,
  IconLink,
  LoadingStateDisplay,
  PfrButton,
  ScaleCard,
  TitleHeader,
} from "@/components";
import { convertUnit, getScaleStatus } from "@/helpers";
import { useOrderItem } from "./useOrderItem.composable";

const props = defineProps({
  orderId: {
    type: String as PropType<string>,
    required: true,
  },
  itemId: {
    type: String as PropType<string>,
    required: true,
  },
});

const { order: orderItem, error, fetchOrderItem } = useOrderItem(props.itemId);

const { t } = useI18n();

// the following consts are used to keep track of the weight from the first
// received weighing since entering the screen.
// Because we aren`t able to access that last weighing, we need to "freeze" the weight
// as a new weighing comes in. The weight of the weighing is the difference between
// the initial weight reached (of the order) when entering the screen and the new weight reached.
const initialWeighingIds: Ref<string[] | undefined> = ref(undefined);
const initialWeightReached: Ref<number | undefined> = ref(undefined);
const weighingReceived = ref(false);

onBeforeMount(async () => {
  await fetchOrderItem(props.orderId, props.itemId);
  initialWeighingIds.value = orderItem.value?.weighingIds;
  initialWeightReached.value = orderItem.value?.weightReached;
});

const currentWeighingId = ref<string | undefined>(undefined);
const weight = ref<number>(0);

const totalRemainingWeight = computed(
  () =>
    (orderItem.value?.targetWeight || 0) - (initialWeightReached.value || 0),
);

watch(orderItem, (newVal) => {
  // when we already received a weighing do no update weight
  if (!weighingReceived.value) {
    weight.value = newVal?.weightPromised || 0;
  }
  if (
    newVal?.weighingIds?.length === undefined ||
    initialWeighingIds.value?.length === undefined
  )
    return;

  if (newVal.weighingIds.length === initialWeighingIds.value.length + 1) {
    weight.value =
      (newVal.weightReached || 0) - (initialWeightReached.value || 0);
    currentWeighingId.value = newVal.weighingIds
      .filter((id) => !initialWeighingIds.value!.includes(id))
      .at(0);
    weighingReceived.value = true;
  }
});

const loadingState = computed(() => {
  if (error.value) {
    if (error.value.networkError) return LoadingState.NetworkError;
    return LoadingState.GraphqlError;
  }
  if (!orderItem.value) return LoadingState.Loading;
  return LoadingState.Success;
});

const convertedVehicleCapacity = computed(() => {
  if (!orderItem.value?.vehicle?.capacityInKg) return null;

  return convertUnit(
    orderItem.value.vehicle.capacityInKg,
    Unit.kg,
    orderItem.value.unit,
  );
});

const maxWeight = computed(() => {
  if (!orderItem.value) return 0;
  if (!convertedVehicleCapacity.value) return totalRemainingWeight.value;

  const min: number = Math.min(
    convertedVehicleCapacity.value,
    totalRemainingWeight.value,
  );

  return Math.max(min, 0);
});

const status = computed(() => {
  if (!orderItem.value) return ScaleStatus.Pending;
  return getScaleStatus(
    weight.value,
    convertedVehicleCapacity.value,
    totalRemainingWeight.value,
    convertUnit(50, Unit.kg, orderItem.value.unit),
  );
});
const canCancelOrder = computed(
  () =>
    !currentWeighingId.value &&
    status.value === ScaleStatus.Pending &&
    orderItem.value?.orderNumber,
);

const buttonText = computed(() => {
  if (
    status.value === ScaleStatus.VehicleOverloaded ||
    status.value === ScaleStatus.OrderOverloaded
  )
    return t("views.loadingStatus.ignoreOverload");
  return t("views.loadingStatus.confirm");
});
</script>

<template>
  <PageLayout>
    <template #header>
      <TitleHeader :heading="t('views.loadingStatus.heading')">
        <template #left>
          <IconLink
            :to="{
              name: RouteName.OrderShow,
              params: {
                orderId,
                itemId,
              },
            }"
          />
        </template>
        <template #right>
          <CancelOrderDialog
            v-if="canCancelOrder"
            :order-number="orderItem?.orderNumber"
            :order-id="orderId"
          />
        </template>
      </TitleHeader>
    </template>

    <template #main>
      <LoadingStateDisplay :loading-state="loadingState">
        <template #default>
          <div v-if="orderItem" class="loading-status-view">
            <span class="loading-status-view--description">
              {{ t("views.loadingStatus.description") }}
            </span>
            <ScaleCard
              :status="status"
              :material="orderItem.material?.name || ''"
              :current-weight="weight"
              :current-weight-unit="orderItem.unit"
              :target-weight="maxWeight"
              :target-weight-unit="orderItem.unit"
              :order-number="orderItem.orderNumber"
            />
          </div>
        </template>
      </LoadingStateDisplay>
    </template>

    <template #mainFooter>
      <PfrButton
        :is="ButtonElement.RouterLink"
        v-if="currentWeighingId"
        :full-width="true"
        :to="{
          name: RouteName.WeighingConfirmation,
          params: { weighingId: currentWeighingId },
        }"
      >
        {{ buttonText }}
      </PfrButton>
    </template>
  </PageLayout>
</template>

<style>
.loading-status-view--description {
  @apply text-18/28;

  display: block;
  margin-block-end: var(--spacing-64);
  text-align: center;
  color: var(--color-text-near-light);
}

.loading-status-view--call {
  margin-block-end: var(--spacing-24);
}
</style>
