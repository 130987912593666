<script setup lang="ts">
import { computed, onBeforeMount, type PropType } from "vue";
import { storeToRefs } from "pinia";
import { PageLayout } from "@/layouts";
import { useOrderStore, useWeighingStore } from "@/stores";
import {
  TitleHeader,
  PfrButton,
  DeliveryDetailList,
  OrderDetailList,
} from "@/components";
import { IconName, ButtonElement, RouteName } from "@/types";
import { useBackLink } from "@/composables";
import { useLink } from "vue-router";

const { firstStepLink } = useBackLink();

const props = defineProps({
  orderId: {
    type: String as PropType<string>,
    required: true,
  },
  itemId: {
    type: String as PropType<string>,
    required: true,
  },
  weighingId: {
    type: String as PropType<string>,
    required: true,
  },
});

const weighingStore = useWeighingStore();
const orderStore = useOrderStore();
const { fetchWeighing } = weighingStore;

const { weighing } = storeToRefs(weighingStore);
const { order } = storeToRefs(orderStore);

const { href } = useLink({
  to: firstStepLink({
    orderId: props.orderId,
    itemId: props.orderId,
    orderState: order.value?.state,
  }),
});

onBeforeMount(() =>
  fetchWeighing(props.orderId, props.itemId, props.weighingId),
);

const deliveryNoteLink = computed(() => ({
  name: RouteName.DeliveryConfirm,
  params: {
    weighingId: props.weighingId,
    orderId: props.orderId,
    itemId: props.itemId,
  },
}));
</script>

<template>
  <PageLayout>
    <template #header>
      <TitleHeader :heading="$t('views.deliverySummary.heading')">
        <template #right>
          <pfreundt-button
            :href="href"
            variant="text"
            color="primary"
            button-type="link"
            size="xsmall"
          >
            {{ $t("navigation.cancel") }}
          </pfreundt-button>
        </template>
      </TitleHeader>
    </template>
    <template #main>
      <div v-if="weighing" class="delivery-summary-view">
        <span class="delivery-summary-view--instruction">
          {{ $t("views.deliverySummary.instruction") }}
        </span>
        <div class="delivery-summary-view--summary">
          <div class="delivery-summary-view--order">
            {{
              $t("deliveryNote.detailsHeading", {
                value: weighing?.bonNumber,
              })
            }}
          </div>
          <OrderDetailList :has-weight-row="true" :entity="weighing" />
          <DeliveryDetailList :order-item="weighing" />
        </div>
      </div>
    </template>
    <template #mainFooter>
      <PfrButton
        :is="ButtonElement.RouterLink"
        :full-width="true"
        :icon="IconName.File"
        :to="deliveryNoteLink"
      >
        {{ $t("views.deliverySummary.openDeliveryNote") }}
      </PfrButton>
    </template>
  </PageLayout>
</template>

<style>
.delivery-summary-view {
  margin-block-end: var(--spacing-24);
}

.delivery-summary-view--instruction {
  display: block;
  margin-block-end: var(--spacing-24);
  border-block-end: var(--gray-7) 1px solid;
  padding: var(--spacing-24);
  text-align: center;
}

.delivery-summary-view--summary {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-24);
  inline-size: 100%;
  margin-block-end: var(--spacing-64);
}

.delivery-summary-view--order {
  font-weight: 700;
}
</style>
