<script setup lang="ts">
import { onBeforeMount } from "vue";
import { storeToRefs } from "pinia";
import { PageLayout } from "@/layouts";
import {
  LoadingStateDisplay,
  OrderIndexMain,
  SkeletonOrderIndex,
} from "@/components";
import { OrderState, LoadingState } from "@/types";
import { useOrdersStore } from "@/stores";
import { useI18n } from "vue-i18n";

const ordersStore = useOrdersStore();
const { fetchOrders } = ordersStore;
const { orders: orderItems, fetching } = storeToRefs(ordersStore);

const { t } = useI18n();

onBeforeMount(() => {
  fetchOrders([OrderState.Closed], null, "");
});

const search = (searchValue: string) => {
  fetchOrders([OrderState.Closed], null, searchValue);
};
</script>

<template>
  <PageLayout :heading="t('views.closedOrders.heading')" @search="search">
    <template #main>
      <LoadingStateDisplay
        :loading-state="fetching ? LoadingState.Loading : LoadingState.Success"
      >
        <template #loading>
          <SkeletonOrderIndex :is-closed="true" />
        </template>
        <template #default>
          <OrderIndexMain v-if="orderItems" :combined-orders="orderItems" />
        </template>
      </LoadingStateDisplay>
    </template>
  </PageLayout>
</template>
