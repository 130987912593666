<script setup lang="ts">
import { onBeforeMount, computed, type PropType } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useOrderStore, useUserStore } from "@/stores";
import { PageLayout } from "@/layouts";
import {
  IconLink,
  TitleHeader,
  DeliveryDetailList,
  OrderDetailList,
  PfrButton,
} from "@/components";
import {
  ButtonElement,
  OrderDeliveryState,
  RouteName,
  ToastStatus,
} from "@/types";
import {
  useCheckDriverAndVehicleId,
  useBackLink,
  useToasts,
} from "@/composables";
import { useRouter } from "vue-router";

const props = defineProps({
  orderId: {
    type: String as PropType<string>,
    required: true,
  },
  itemId: {
    type: String as PropType<string>,
    required: true,
  },
});

const { overviewLink } = useBackLink();

const { t } = useI18n();
useCheckDriverAndVehicleId();

const router = useRouter();

const orderStore = useOrderStore();
const { fetchOrder, acceptDeliveryRequest } = orderStore;
const { order } = storeToRefs(orderStore);

const userStore = useUserStore();
const { vehicleId, driverId } = storeToRefs(userStore);

const hasOrderWeighings = computed(() => order.value?.weighingIds?.length);

const orderStarted = computed(
  () => order.value?.deliveryState !== OrderDeliveryState.NotTracking,
);

const buttonText = computed(() => {
  if (hasOrderWeighings.value) {
    return t("views.orderDetails.nextLoading");
  }
  return t("views.orderDetails.continue");
});

const nextPageLink = computed(() => {
  if (order.value?.deliveryState === OrderDeliveryState.InArrival) {
    return {
      name: RouteName.ConfirmArrival,
      params: { orderId: props.orderId, itemId: props.itemId },
    };
  }
  return {
    name: RouteName.Loading,
    params: { orderId: props.orderId, itemId: props.itemId },
  };
});

onBeforeMount(() => {
  fetchOrder(props.orderId, props.itemId);
});

const { addToast } = useToasts();

const handleOrderStart = async () => {
  const isSuccess = await acceptDeliveryRequest(
    props.orderId,
    props.itemId,
    vehicleId.value,
    driverId.value,
  );

  if (!isSuccess) {
    return;
  }

  addToast(
    t("views.orderDetails.started", { id: order.value?.orderNumber }),
    ToastStatus.Success,
  );
  router.push({
    name: RouteName.ConfirmArrival,
    params: { orderId: props.orderId, itemId: props.itemId },
  });
};
</script>

<template>
  <PageLayout>
    <template #header>
      <TitleHeader :heading="$t('views.orderDetails.heading')">
        <template #left>
          <IconLink
            :to="
              overviewLink({
                orderState: order?.state,
                userVehicleId: vehicleId,
                orderVehicleId: order?.vehicle?.id,
              })
            "
          />
        </template>
      </TitleHeader>
    </template>

    <template #main>
      <h1 class="order-show-view--heading">
        {{ $t("order.heading", { id: order?.orderNumber }) }}
      </h1>
      <div class="order-show-view--details">
        <OrderDetailList v-if="order" :entity="order" />
      </div>
      <DeliveryDetailList v-if="order" :order-item="order" />
    </template>

    <template #mainFooter>
      <PfrButton
        :is="ButtonElement.RouterLink"
        v-if="hasOrderWeighings"
        :full-width="true"
        :to="{
          name: RouteName.DeliveryNoteIndex,
          params: { orderId: props.orderId, itemId: props.itemId },
        }"
      >
        {{ t("views.orderDetails.showDeliveryReceipts") }}
      </PfrButton>
      <PfrButton
        :is="ButtonElement.Button"
        v-if="!orderStarted"
        :disabled="!order"
        :full-width="true"
        @click="handleOrderStart"
      >
        {{ t("views.orderDetails.start") }}
      </PfrButton>
      <PfrButton
        :is="ButtonElement.RouterLink"
        v-else
        :full-width="true"
        :to="nextPageLink"
      >
        {{ buttonText }}
      </PfrButton>
    </template>
  </PageLayout>
</template>

<style>
.order-show-view--heading {
  @apply text-20/28;

  margin-block-end: var(--spacing-24);
  font-weight: 700;
}

.order-show-view--details {
  margin-block-end: var(--spacing-40);
}
</style>
