<script setup lang="ts">
import { computed, onBeforeMount, type PropType } from "vue";
import { storeToRefs } from "pinia";
import { PageLayout } from "@/layouts";
import {
  TitleHeader,
  PfrButton,
  DeliveryNoteDetailList,
  LoadingStateDisplay,
  IconLink,
} from "@/components";
import {
  ButtonElement,
  LoadingState,
  OrderState,
  SignatureRole,
  RouteName,
} from "@/types";
import { useWeighingStore, useOrderStore, useUserStore } from "@/stores";
import { fallback } from "@/helpers";

const props = defineProps({
  orderId: {
    type: String as PropType<string>,
    required: true,
  },
  itemId: {
    type: String as PropType<string>,
    required: true,
  },
  weighingId: {
    type: String as PropType<string>,
    required: true,
  },
});

const weighingStore = useWeighingStore();
const { fetchWeighing } = weighingStore;
const { weighing, error, fetching } = storeToRefs(weighingStore);

const orderStore = useOrderStore();
const { fetchOrder } = orderStore;
const { order } = storeToRefs(orderStore);

const userStore = useUserStore();
const { vehicleId } = storeToRefs(userStore);

onBeforeMount(() => {
  fetchOrder(props.orderId, props.itemId);
  fetchWeighing(props.orderId, props.itemId, props.weighingId);
});

const loadingState = computed(() => {
  if (error.value?.networkError) return LoadingState.NetworkError;
  if (error.value) return LoadingState.GraphqlError;
  if (fetching.value) return LoadingState.Loading;
  return LoadingState.Success;
});

const overviewLink = computed(() => {
  if (order.value && order.value.state === OrderState.Closed)
    return { name: RouteName.ClosedOrderIndex };

  return order.value?.vehicle?.id === vehicleId.value
    ? { name: RouteName.MyOrders }
    : { name: RouteName.OpenOrders };
});

const orderOverviewLink = computed(() => ({
  name: RouteName.DeliveryNoteIndex,
  params: {
    orderId: props.orderId,
    itemId: props.itemId,
  },
}));

const customerSignature = computed(() =>
  weighing.value?.signatures?.find(
    (signature) => signature.role === SignatureRole.Customer,
  ),
);

const customerName = computed(() => customerSignature.value?.description);

const customerSignatureUrl = computed(() => customerSignature.value?.imageUrl);
</script>

<template>
  <PageLayout>
    <template #header>
      <TitleHeader :heading="$t('deliveryNote.heading')">
        <template #left>
          <IconLink :to="orderOverviewLink" />
        </template>
      </TitleHeader>
    </template>
    <template #main>
      <LoadingStateDisplay :loading-state="loadingState">
        <template #default>
          <div v-if="weighing" class="closed-delivery-note-view">
            <div class="closed-delivery-note-view--summary">
              <h2 class="closed-delivery-note-view--section-headline">
                {{
                  $t("deliveryNote.detailsHeading", {
                    value: weighing.bonNumber,
                  })
                }}
              </h2>
              <DeliveryNoteDetailList :weighing="weighing" />
            </div>
            <h2 class="closed-delivery-note-view--section-headline">
              {{ $t("deliveryNote.acceptedFrom") }}
            </h2>
            <h3 class="closed-delivery-note-view--name-heading">
              {{ $t("deliveryNote.name") }}
            </h3>
            <span class="closed-delivery-note-view--name">
              {{ fallback(customerName) }}
            </span>
            <h3 class="closed-delivery-note-view--section-headline">
              {{ $t("deliveryNote.signatureForConfirmation") }}
            </h3>
            <div class="closed-delivery-note-view--signature">
              <img
                class="closed-delivery-note-view--signature-img"
                :src="customerSignatureUrl"
              />
            </div>
          </div>
        </template>
      </LoadingStateDisplay>
    </template>
    <template #mainFooter>
      <PfrButton
        :is="ButtonElement.RouterLink"
        :full-width="true"
        :to="overviewLink"
      >
        {{ $t("navigation.toClosedOrdersOverview") }}
      </PfrButton>
    </template>
  </PageLayout>
</template>

<style>
.closed-delivery-note-view--name-heading {
  margin-block-end: var(--spacing-8);
}

.closed-delivery-note-view--name {
  display: block;
  margin-block-end: var(--spacing-24);
  border: 1px solid var(--color-border-near-light);
  border-radius: 4px;
  padding-block: var(--spacing-8);
  padding-inline: var(--spacing-16);
  background-color: var(--color-background-light);
}

.closed-delivery-note-view--signature-img {
  max-block-size: 100%;
}

.closed-delivery-note-view--signature {
  display: flex;
  justify-content: center;
  align-items: center;
  inline-size: 100%;
  block-size: var(--signature-button-height);
  border-radius: 4px;
  box-shadow: var(--elevation-13);
  background-color: var(--color-background-light);
}

.closed-delivery-note-view--summary {
  margin-block-end: var(--spacing-24);
  border-block-end: var(--color-border-near-light) 1px solid;
  padding-block-end: var(--spacing-24);
}

.closed-delivery-note-view--section-headline {
  margin-block-end: var(--spacing-16);
  font-weight: 700;
}
</style>
