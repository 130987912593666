<script setup lang="ts">
import { computed, onBeforeMount, type PropType } from "vue";
import { useI18n } from "vue-i18n";
import { useForm } from "vee-validate";
import { storeToRefs } from "pinia";
import { PageLayout } from "@/layouts";
import {
  LoadingStateDisplay,
  PfrButton,
  SignatureField,
  TitleHeader,
  OrderDetailList,
  PfrForm,
} from "@/components";
import { useLink, useRouter } from "vue-router";
import { ButtonElement, LoadingState, RouteName, ToastStatus } from "@/types";
import { useOrderStore, useUserStore, useWeighingStore } from "@/stores";
import { getUniqueId } from "@/helpers";
import {
  useBackLink,
  useToasts,
  useWeighingRedirectToStep,
} from "@/composables";

const { firstStepLink } = useBackLink();

const props = defineProps({
  orderId: {
    type: String as PropType<string>,
    required: true,
  },
  itemId: {
    type: String as PropType<string>,
    required: true,
  },
  weighingId: {
    type: String as PropType<string>,
    required: true,
  },
});

const { t } = useI18n();
const weighingStore = useWeighingStore();
const orderStore = useOrderStore();
const { fetchWeighing, saveDriversSignature } = weighingStore;
const {
  weighing,
  error: weighingError,
  fetching: weighingFetching,
} = storeToRefs(weighingStore);
const {
  order,
  error: orderError,
  fetching: orderFetching,
} = storeToRefs(orderStore);

const error = computed(() => weighingError.value || orderError.value);
const fetching = computed(() => weighingFetching.value || orderFetching.value);

onBeforeMount(() =>
  fetchWeighing(props.orderId, props.itemId, props.weighingId),
);
useWeighingRedirectToStep(weighing);

interface WeighingConfirmationFields {
  signature: Blob;
}

const validationSchema = {
  signature: "required",
};

const { handleSubmit, meta: formMeta } = useForm<WeighingConfirmationFields>({
  validationSchema,
});

const deliverySummaryLink = computed(() => ({
  name: RouteName.DeliverySummary,
  params: {
    weighingId: props.weighingId,
    orderId: props.orderId,
    itemId: props.itemId,
  },
}));

const userStore = useUserStore();
const router = useRouter();
const { addToast } = useToasts();
const { user } = storeToRefs(userStore);
const userName = computed(() =>
  `${user.value?.firstname} ${user.value?.lastname}`.trim(),
);

const onSubmit = handleSubmit(async ({ signature }) => {
  const isSuccess = await saveDriversSignature(signature, userName.value);

  if (!isSuccess) {
    addToast(t("request.error"), ToastStatus.Error);
    return;
  }

  addToast(t("views.weighingConfirmation.confirmMessage"), ToastStatus.Success);
  router.push(deliverySummaryLink.value);
});

const formId = getUniqueId("weighingConfirmForm");

const loadingState = computed(() => {
  if (error.value) {
    if (error.value.networkError) return LoadingState.NetworkError;
    return LoadingState.GraphqlError;
  }
  if (fetching.value) return LoadingState.Loading;
  return LoadingState.Success;
});

const { href } = useLink({
  to: firstStepLink({
    orderId: props.orderId,
    itemId: props.itemId,
    orderState: order.value?.state,
  }),
});
</script>

<template>
  <PageLayout>
    <template #header>
      <TitleHeader :heading="$t('views.weighingConfirmation.heading')">
        <template #right>
          <pfreundt-button
            :href="href"
            variant="text"
            color="primary"
            button-type="link"
            size="xsmall"
          >
            {{ t("navigation.cancel") }}
          </pfreundt-button>
        </template>
      </TitleHeader>
    </template>
    <template #main>
      <LoadingStateDisplay :loading-state="loadingState">
        <template #default>
          <div v-if="weighing">
            <div class="weighing-confirmation--order">
              {{
                t("deliveryNote.detailsHeading", { value: weighing.bonNumber })
              }}
            </div>
            <div class="weighing-confirmation--infos">
              <OrderDetailList :has-weight-row="true" :entity="weighing" />
            </div>
            <PfrForm :id="formId" @submit-form="onSubmit">
              <div class="weighing-confirmation--signature">
                <SignatureField field-name="signature" />
              </div>
            </PfrForm>
          </div>
        </template>
      </LoadingStateDisplay>
    </template>
    <template #mainFooter>
      <PfrButton
        :is="ButtonElement.Button"
        type="submit"
        :form="formId"
        :full-width="true"
        :disabled="!formMeta.valid"
      >
        {{ t("navigation.confirm") }}
      </PfrButton>
    </template>
  </PageLayout>
</template>

<style>
.weighing-confirmation--order {
  @apply text-16/24;

  inline-size: 100%;
  margin-block-end: var(--spacing-8);
  font-weight: 700;
}

.weighing-confirmation--infos {
  margin-block-end: var(--spacing-24);
}

.weighing-confirmation--signature {
  block-size: var(--signature-field-height);
  margin-block-end: var(--spacing-40);
}
</style>
