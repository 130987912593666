<script setup lang="ts">
import { computed, onBeforeMount, type PropType } from "vue";
import { storeToRefs } from "pinia";
import { PageLayout } from "@/layouts";
import {
  TitleHeader,
  IconLink,
  DeliveryDetailList,
  OrderDetailList,
  LinkCard,
  DeliveryNoteSummaryList,
  LoadingStateDisplay,
} from "@/components";
import { useOrderStore, useWeighingsStore } from "@/stores";
import { LoadingState, RouteName, type ExtendedWeighing } from "@/types";
import { isAnySignatureMissing } from "@/helpers";
import { useBackLink } from "@/composables";

const props = defineProps({
  orderId: {
    type: String as PropType<string>,
    required: true,
  },
  itemId: {
    type: String as PropType<string>,
    required: true,
  },
});

const orderStore = useOrderStore();
const weighingsStore = useWeighingsStore();

const { firstStepLink } = useBackLink();

const { fetchOrder } = orderStore;
const {
  order,
  error: orderError,
  fetching: orderFetch,
} = storeToRefs(orderStore);

const { fetchWeighings } = weighingsStore;
const {
  weighings,
  error: weighingsError,
  fetching: weighingsFetch,
} = storeToRefs(weighingsStore);

onBeforeMount(() => {
  fetchOrder(props.orderId, props.itemId);
  fetchWeighings(props.orderId, props.itemId);
});

const getLinkToWeighing = (weighing: ExtendedWeighing) => {
  const name: RouteName = isAnySignatureMissing(weighing)
    ? RouteName.WeighingConfirmation
    : RouteName.ClosedDeliveryNote;
  return {
    name,
    params: {
      orderId: props.orderId,
      itemId: props.itemId,
      weighingId: weighing.id,
    },
  };
};
const loadingState = computed(() => {
  if (orderError.value?.networkError || weighingsError.value?.networkError) {
    return LoadingState.NetworkError;
  }
  if (orderError.value || weighingsError.value)
    return LoadingState.GraphqlError;
  if (orderFetch.value || weighingsFetch.value) return LoadingState.Loading;
  return LoadingState.Success;
});
</script>

<template>
  <PageLayout>
    <template #header>
      <TitleHeader :heading="$t('views.closedOrderShow.heading')">
        <template #left>
          <IconLink
            :to="firstStepLink({ orderState: order?.state, orderId, itemId })"
          />
        </template>
      </TitleHeader>
    </template>
    <template #main>
      <LoadingStateDisplay :loading-state="loadingState">
        <template #default>
          <div class="closed-order-show-view">
            <div v-if="order" class="closed-order-show-view--details">
              <OrderDetailList :has-weight-row="true" :entity="order" />
              <DeliveryDetailList :order-item="order" />
            </div>
            <div
              v-if="order && weighings"
              class="closed-order-show-view--weighings"
            >
              <LinkCard
                v-for="weighing in weighings"
                :key="weighing.id"
                :title="
                  $t('deliveryNote.detailsHeading', {
                    value: weighing.bonNumber,
                  })
                "
                :link="getLinkToWeighing(weighing)"
              >
                <DeliveryNoteSummaryList :weighing="weighing" />
              </LinkCard>
            </div>
          </div>
        </template>
      </LoadingStateDisplay>
    </template>
  </PageLayout>
</template>

<style>
.closed-order-show-view--details {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-24);
  margin-block-end: var(--spacing-24);
  border-block-end: var(--gray-7) 1px solid;
  padding: var(--spacing-24);
}

.closed-order-show-view--weighings {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-24);
}
</style>
